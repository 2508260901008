/*============================================================
=            Como utilizar el hook useBreakpoins            =
==============================================================*/

/* 
import React from "react"
import useBreakpoint from "../hooks/useBreakpoins"
 
const queries = {
  xs: "(min-width: 0)",
  sm: "(min-width: 576px)",
  md: "(min-width: 768px)",
  lg: "(min-width: 992px)",
  xl: "(min-width: 1200px)",
}
 
const BpUse = () => {
  const breakpoints = useBreakpoint(queries)

  const { xs, sm, md, lg, xl } = breakpoints

  return (
    <>
      BP
      {(xl && <h1>XL</h1>) ||
        (lg && <h1>LG</h1>) ||
        (md && <h1>MD</h1>) ||
        (sm && <h1>SM</h1>) ||
        (xs && <h1>XS</h1>)}
    </>
  )
}
*/

/*=====  End of Como utilizar el hook useBreakpoins  ======*/

import { useState, useEffect } from "react"

const defaultMediaQueries = {
  xs: "(min-width: 0)",
  sm: "(min-width: 576px)",
  md: "(min-width: 768px)",
  lg: "(min-width: 992px)",
  xl: "(min-width: 1200px)",
}

const useBreakpoint = (queries = defaultMediaQueries) => {
  const [queryMatch, setQueryMatch] = useState({})

  useEffect(() => {
    const mediaQueryLists = {}
    const keys = Object.keys(queries)

    // To check whether event listener is attached or not
    let isAttached = false

    const handleQueryListener = () => {
      const updatedMatches = keys.reduce((acc, media) => {
        acc[media] = !!(
          mediaQueryLists[media] && mediaQueryLists[media].matches
        )
        return acc
      }, {})
      //Setting state to the updated matches
      // when document either starts or stops matching a query
      setQueryMatch(updatedMatches)
    }

    if (window && window.matchMedia) {
      const matches = {}
      keys.forEach(media => {
        if (typeof queries[media] === "string") {
          mediaQueryLists[media] = window.matchMedia(queries[media])
          matches[media] = mediaQueryLists[media].matches
        } else {
          matches[media] = false
        }
      })
      //Setting state to initial matching queries
      setQueryMatch(matches)
      isAttached = true
      keys.forEach(media => {
        if (typeof queries[media] === "string") {
          mediaQueryLists[media].addListener(handleQueryListener)
        }
      })
    }

    return () => {
      //If event listener is attached then remove it when deps change
      if (isAttached) {
        keys.forEach(media => {
          if (typeof queries[media] === "string") {
            mediaQueryLists[media].removeListener(handleQueryListener)
          }
        })
      }
    }
  }, [queries])

  return queryMatch
}

export default useBreakpoint
