import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { graphql, Link, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import SEO from "../../components/seo"
import Layout from "../../components/Layout"
import useBreakpoint from "../../hooks/useBreakpoints"

/* Styles */
import "../../styles/pages/the-hybrid.scss"

const TheHybrid = () => {
  const {
    bgTheHibrid,
    bgMovilTheHibrid,
    layerMovil,
    logoMkt,
    logoGwt,
    logoTech,
  } = useStaticQuery(
    graphql`
      query {
        bgTheHibrid: file(
          relativePath: { eq: "the-hybrid/desktop-web-the-hybrid.png" }
        ) {
          childImageSharp {
            fluid(quality: 75, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        bgMovilTheHibrid: file(
          relativePath: { eq: "the-hybrid/mobile-bg-hybrid.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 75, maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        layerMovil: file(
          relativePath: { eq: "the-hybrid/mobile-layer-hybrid.png" }
        ) {
          childImageSharp {
            fluid(quality: 75) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        logoMkt: file(relativePath: { eq: "the-hybrid/logo-mkt.png" }) {
          childImageSharp {
            fluid(quality: 75) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        logoGwt: file(relativePath: { eq: "the-hybrid/logo-gwt.png" }) {
          childImageSharp {
            fluid(quality: 75) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        logoTech: file(relativePath: { eq: "the-hybrid/logo-tech.png" }) {
          childImageSharp {
            fluid(quality: 75) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        logoMch: file(relativePath: { eq: "the-hybrid/logo-mch.png" }) {
          childImageSharp {
            fluid(quality: 75) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  // Set ImageData.
  const imageBgTheHibrid = bgTheHibrid.childImageSharp.fluid
  const imagebgMovilTheHibrid = bgMovilTheHibrid.childImageSharp.fluid
  const imgLayerMovil = layerMovil.childImageSharp.fluid
  const imgLogoMkt = logoMkt.childImageSharp.fluid
  const imgLogoGwt = logoGwt.childImageSharp.fluid
  const imgLogoTech = logoTech.childImageSharp.fluid

  // Breakpoints
  const breakpoints = useBreakpoint()
  const { md } = breakpoints

  const sources = [
    imagebgMovilTheHibrid,
    {
      ...imageBgTheHibrid,
      media: `(min-width: 768px)`,
    },
  ]

  return (
    <Layout bgImage={sources}>
      <SEO
        title="The Hybrid"
        keywords={[
          `Agencia`,
          `Transformacion Digital`,
          `The Hybrid`,
          `Growth`,
          `Rosario`,
          `Innovación`,
        ]}
      />
      <section id="the-hybrid-page" className="pt-lg-5">
        <Container>
          <Row>
            <Col lg={8}>
              {md || (
                <div className="movil-layer-thehybrid">
                  <Image
                    className="img-fluid"
                    fluid={imgLayerMovil}
                    alt="bg-shape"
                  />
                </div>
              )}
              <h1 className="title gradient-title text-white">The Hybrid</h1>
              <p className="text-white">
                Somos un organismo vivo inteligente conformado por un equipo
                especializado en marketing, innovación, software y nuevos
                negocios de base tecnológica. Nativos de la Transformación
                Digital, integramos un ecosistema propio que potencia los
                modelos de expansión de cualquier organización.
              </p>
            </Col>
            <Col xs={12}>
              <div className="the-hybrid-page-link-wrap">
                <Link
                  id="l1"
                  className="the-hybrid-page-link"
                  to="/the-hybrid/mkt"
                >
                  <Image className="img-fluid" fluid={imgLogoMkt} />
                </Link>
                <Link
                  id="l2"
                  className="the-hybrid-page-link"
                  to="/the-hybrid/gwt"
                >
                  <Image className="img-fluid" fluid={imgLogoGwt} />
                </Link>
                <Link
                  id="l3"
                  className="the-hybrid-page-link"
                  to="/the-hybrid/tech"
                >
                  <Image className="img-fluid" fluid={imgLogoTech} />
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default TheHybrid
